// @flow
import { isTablet as isTabletBrowser, isMobile as isMobileBrowser } from "react-device-detect";
import { Storage } from 'aws-amplify';

/**
 * Detect mobile
 */
export function isMobile() {
  return isMobileBrowser;
}

export function isTablet() {
  return isTabletBrowser;
}

export function uploadFile(file, fileName, uploadBucket, folderName, contentType){
  return new Promise((resolve, reject) => {
      console.log(`Uploading ${fileName}`);
      // AWS amplify otherise uploads to public, private, protected folders in the bucket. Deafult is public. 
      // We need to customise so the file is uploaded to the folder with the name we specify.
      const customPrefix = {
          public: folderName ? folderName + '/' : ''
      };
      const content_type = contentType ? contentType : '';
      Storage.configure({ bucket: uploadBucket });
      Storage.put(fileName, file, { 
        customPrefix: customPrefix,
        contentType: content_type
      })
          .then((result) => {
              console.log(result);
              resolve(fileName);
          })
          .catch((err) => {
              reject(fileName);
              console.log(err);
          });
  });
}

export function getFileLink(fileName, bucket, folderName){
  return new Promise((resolve, reject) => {
      const customPrefix = {
          public: folderName + '/'
      };
      Storage.configure({ bucket: bucket });
      Storage.get(fileName, { customPrefix: customPrefix })
          .then((result) => {
              resolve(result);
          })
          .catch((err) => {
              reject(err);
              console.log(err);
          });
  });
}

export const getFileExtension = (filename) => {
  return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
}

export function sortOuList(ouList) {
  return ouList.sort((a,b) =>((a.title).toLowerCase() > (b.title).toLowerCase()) ? 1: -1);
}

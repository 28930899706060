export const CONNECT_FEATURES = {
    CHEMICALS: {
        displayName: 'Chemicals',
    }, CONTRACTORS: {
        displayName: 'Contractors',
    }, DOCUMENTS: {
        displayName: 'Documents',
    }, EQUIPMENT: {
        displayName: 'Equipment',
    }, EPAR_V: {
        displayName: 'EparV',
    }, AUDUBON: {
        displayName: 'Audubon',
    }, HAZARDS: {
        displayName: 'Hazards',
        dependencies: ['RISK_REGISTER'],
    }, INCIDENTS: {
        displayName: 'Incidents',
        dependencies: ['RISK_REGISTER'],
    }, INSPECTIONS: {
        displayName: 'Inspections',
    }, JOB_PLANNER: {
        displayName: 'Job Planner',
    }, QUALIFICATIONS: {
        displayName: 'Qualifications',
    }, REPORTS: {
        displayName: 'Reports',
    }, RISK_REGISTER: {
        displayName: 'Risk Register',
    }, SMART: {
        displayName: 'Smart',
    }, TRAINING_ACADEMY: {
        displayName: 'Training Academy',
    }, EPAR_V: {
        displayName: 'EparV',
    }
}

export const CONNECT_FEATURE_NAMES = {
    CHEMICALS: 'CHEMICALS',
    CONTRACTORS: 'CONTRACTORS',
    DOCUMENTS: 'DOCUMENTS',
    EQUIPMENT: 'EQUIPMENT',
    EPAR_V: 'EPAR_V',
    HAZARDS: 'HAZARDS',
    INCIDENTS: 'INCIDENTS',
    INSPECTIONS: 'INSPECTIONS',
    JOB_PLANNER: 'JOB_PLANNER',
    QUALIFICATIONS: 'QUALIFICATIONS',
    REPORTS: 'REPORTS',
    RISK_REGISTER: 'RISK_REGISTER',
    SMART: 'SMART',
    TRAINING_ACADEMY: 'TRAINING_ACADEMY'
}

export const FEATURES_TO_OFF = [CONNECT_FEATURE_NAMES.CONTRACTORS, CONNECT_FEATURE_NAMES.JOB_PLANNER, CONNECT_FEATURE_NAMES.EPAR_V];

export const CONNECT_CUSTOM_FEATURES = [
    {
        displayName: 'NATIONAL MAP',
        key: 'NATIONAL_MAP'
    }
];

export const CONNECT_CUSTOM_FEATURES_STATUS = {
    ENABLE: 1,
    DISABLE: 0
}

export const SITE_TYPE = {
    ORG_LEVEL: 'ORG_LEVEL',
    OU_LEVEL: 'OU_LEVEL'
}

export const NATIONAL_MAP_INSTANCES = [
    {
        key: 'GOLF',
        name: 'Golf'
    },
    {
        key: 'NON_GOLF',
        name: 'Non Golf'
    },
]
import { Button, Col, Divider, Row } from 'antd';
import React, { Component } from 'react';
import { CONFIG } from '../../../config/app-config';
import moment from 'moment';
import _ from 'lodash';

class NationalMapDetailView extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { s3HostUrl, nationalMapBucket } = CONFIG;
        const { site } = this.props;
        const climateChampion = site.team && site.team.climateChampions.length > 0 ? site.team.climateChampions[0] : {};
        return (
            <div>
                {   
                    site &&
                    <div className='epar_national_map_detail-view'>
                        <Col span={24}>
                            <Divider />
                            <p className='epar_national_map_form_category detail-title'>General Details</p>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>National Map Site Name : </b></Col>
                                <Col span={18}>{site.properties.siteName}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Short Description : </b></Col>
                                <Col span={18}>{site.properties.shortDescription}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Description : </b></Col>
                                <Col span={18}>{site.properties.siteDescription}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Traditional Custodians : </b></Col>
                                <Col span={18}>{site.properties.traditionalOwners}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Profile Image : </b></Col>
                                <Col span={18}>
                                    <img src={`${s3HostUrl}/${nationalMapBucket}/${site.siteId}/profileImage.jpeg?${moment()}`} width="100"/>
                                </Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Banner Image : </b></Col>
                                <Col span={18}>
                                    <img src={`${s3HostUrl}/${nationalMapBucket}/${site.siteId}/bannerImage.jpeg?${moment()}`} width="100"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} hidden={_.isEmpty(climateChampion)}>
                            <Divider />
                            <p className='epar_national_map_form_category detail-title'>Climate Champion Details</p>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Climate Champion's Name : </b></Col>
                                <Col span={18}>{climateChampion.climateChampionName}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Description : </b></Col>
                                <Col span={18}>{climateChampion.climateChampionDescription}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Profile Image : </b></Col>
                                <Col span={18}>
                                    <img src={`${s3HostUrl}/${nationalMapBucket}/${site.siteId}/climateChampionImage.jpeg?${moment()}`} width="100"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Divider />
                            <p className='epar_national_map_form_category detail-title'>Contact Details</p>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Website : </b></Col>
                                <Col span={18}>{site.properties.website || '-'}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Phone Number : </b></Col>
                                <Col span={18}>{site.properties.contactNumber || '-'}</Col>
                            </Row>
                            <Row className='epar_national_map_detail-view_row'>
                                <Col className='detail-properties' span={6}><b>Site Area : </b></Col>
                                <Col span={18}>
                                    <Button disabled>View Map</Button>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                }
            </div>
        );
    }
}

export default NationalMapDetailView;
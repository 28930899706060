export const CONFIG = {
  cognitoUserPoolId: 'us-west-2_gG6IXKyhX',
  userPoolWebClientId: '66474s6s0r49t5psclfirdb9vt',
  cognitoIdentityPoolId: 'us-west-2:26965383-b3b0-44f9-a5f3-ce77e0146fe5',
  awsRegion: 'us-west-2',
  s3Region: 'us-east-2',
  
  baseApiUrl: 'https://www.eparconnectserver.com/dev-',
  apiStage: 'dev',
  
  s3HostUrl: 'https://s3-us-east-2.amazonaws.com',
  chemicalFilesBucket: "dev-us-chemical-files",
  equipmentFilesBucket: "dev-us-connect-equipment-manuals",
  courseFilesBucket: "dev-us-connect-courses",
  orgPublicBucket: 'dev-us-connect-org-public-data',
  nationalMapBucket: 'dev-us-national-map-site-data',
  reportUploadBucket: 'dev-us-connect-report',
  ehsSlideBucket: 'dev-us-connect-ehs-slide-images',
  commonDocumentsBucket: 'dev-us-connect-common-docs',

  reportEndPoint: "https://dev-reporting.eparconnectserver.com",

  adminAPIEndPoint: "https://www.eparconnectserver.com/dev-admin-api/dev",

  connectWebUrl: "https://www.dev-v2.eparconnect.com",
  // tinyMce free version intergrated. dev and qa api keys generated using custom email and prod api key generated using 'nipun@smbapps.com.au' email
  tinyMceApiKey : "cc7ftamcri7qplcy2miqxf1l4hz5cyw67a0hlilszu2vojdy"
}
